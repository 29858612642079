application.register("comp_tests", class extends Stimulus.Controller {
    get ref() {
        return {
            note2: this.targets.find("note2").innerHTML,
            note3: this.targets.find("note3").innerHTML,
            step2: this.targets.find("step2"),
            step2notes: this.targets.find("step2notes"),
            step3: this.targets.find("step3"),
            step3notes: this.targets.find("step3notes"),
            agreeInput: this.targets.find("agree_input")
        }
    }
    connect() {

    }

    changeRequired(idElem, isRequired) {
        if(isRequired) {
            document.getElementById(idElem).setAttribute('required','required');
        } else {
            document.getElementById(idElem).removeAttribute('required');
        }
    }

    changeStep1(e) {

        application["getControllerForElementAndIdentifier"](document.querySelector('.comp_foot'), "comp_foot").changeFooterText(1,e);

        if(e.target.value === "N") {
            this.ref.step2.classList.add("state--active");
            this.changeRequired('step_2_input', true);
            this.ref.step3.classList.add("state--active");
            this.changeRequired('step_3_input', true);
        } else {
            this.ref.step2.classList.remove("state--active");
            this.changeRequired('step_2_input', false);
            this.ref.step3.classList.remove("state--active");
            this.changeRequired('step_3_input', false);


            var radios2 = document.getElementsByName('step2');
            var radios3 = document.getElementsByName('step3');
            for (var i = 0, length = radios2.length; i < length; i++) {
                radios2[i].checked = false;
            }
            for (var i = 0, length = radios3.length; i < length; i++) {
                radios3[i].checked = false;
            }
        }

        application["getControllerForElementAndIdentifier"](document.querySelector('.comp_foot'), "comp_foot").resolveText();
    }

    changeStep2(e) {

        application["getControllerForElementAndIdentifier"](document.querySelector('.comp_foot'), "comp_foot").changeFooterText(2,e);

        if(e.target.value === "Y") {
            this.ref.step2notes.style.display = "block";

        }
        else {
            this.ref.step2notes.style.display = "none";
        }

        application["getControllerForElementAndIdentifier"](document.querySelector('.comp_foot'), "comp_foot").resolveText();
    }

    changeStep3(e) {

        application["getControllerForElementAndIdentifier"](document.querySelector('.comp_foot'), "comp_foot").changeText(e);

        application["getControllerForElementAndIdentifier"](document.querySelector('.comp_foot'), "comp_foot").changeFooterText(3,e);
        if(e.target.value === "Y") {
            this.ref.agreeInput.value = 0;
            this.ref.step3notes.style.display = "block";
        }
        else {
            this.ref.agreeInput.value = 1;
            this.ref.step3notes.style.display = "none";
        }

        application["getControllerForElementAndIdentifier"](document.querySelector('.comp_foot'), "comp_foot").resolveText();
    }

    addNote2(e) {
        let body = e.target.closest(".elm_item_body");
        let notesWrap = body.querySelector(".elm_item_notes");
        notesWrap.insertAdjacentHTML("beforeend", this.ref.note2);
    }

    addNote3(e) {
        let body = e.target.closest(".elm_item_body");
        let notesWrap = body.querySelector(".elm_item_notes");
        notesWrap.insertAdjacentHTML("beforeend", this.ref.note3);
    }
});