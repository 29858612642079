application.register("comp_foot", class extends Stimulus.Controller {
    get ref() {
        return {
            submitButtonText: this.targets.find("submit_button_text"),
            text1: this.targets.find("footer_text_1"),
            text2: this.targets.find("footer_text_2"),
            text3: this.targets.find("footer_text_3"),
        }
    }
    connect() {

        this.resolveText();

    }

    changeFooterText(stepNum,e) {

        /*this.ref.text1.style.display = 'none';
        this.ref.text2.style.display = 'none';
        this.ref.text3.style.display = 'none';

        switch (stepNum) {
            case 1:
                if(e.target.value === "Y") {
                    this.ref.text1.style.display = 'block';
                } else {
                    this.ref.text2.style.display = 'block';
                }
                break;
            case 2:
                if(e.target.value === "Y") {
                    this.ref.text2.style.display = 'block';
                } else {
                    this.ref.text2.style.display = 'block';
                }
                break;
            case 3:
                if(e.target.value === "Y") {
                    this.ref.text3.style.display = 'block';
                } else {
                    this.ref.text2.style.display = 'block';
                }
                break;
        }*/

    }

    resolveText() {

        this.ref.text1.style.display = 'none';
        this.ref.text2.style.display = 'none';
        this.ref.text3.style.display = 'none';

        var radios1 = document.getElementsByName('step1');
        var radios2 = document.getElementsByName('step2');
        var radios3 = document.getElementsByName('step3');

        let radio1value = null;
        let radio2value = null;
        let radio3value = null;

        for (var i = 0, length = radios1.length; i < length; i++) {
            if (radios1[i].checked) {
                radio1value = radios1[i].value;
                break;
            }
        }
        for (var i = 0, length = radios2.length; i < length; i++) {
            if (radios2[i].checked) {
                radio2value = radios2[i].value;
                break;
            }
        }
        for (var i = 0, length = radios3.length; i < length; i++) {
            if (radios3[i].checked) {
                radio3value = radios3[i].value;
                break;
            }
        }

        if(radio3value === 'Y') {
            this.ref.text3.style.display = 'block';
        } else if(radio2value === 'Y') {
            this.ref.text2.style.display = 'block';
        } else if(radio1value === 'N') {
            this.ref.text1.style.display = 'block';
        } else {
            this.ref.text1.style.display = 'block';
        }

    }

    changeText(e) {
        if(e.target.value === "Y") {
            this.ref.submitButtonText.innerHTML = this.ref.submitButtonText.getAttribute('data-disagree-text');
        }
        else {
            this.ref.submitButtonText.innerHTML = this.ref.submitButtonText.getAttribute('data-agree-text');
        }
    }

    submitForm(e) {
        let elem = e.target;
        let form = document.getElementById('protocol-form');
        if(form.checkValidity()) {
            elem.setAttribute('disabled','disabled');
        }

        setTimeout(function() {
            elem.removeAttribute('disabled');
        }, 10000);

        /*document.getElementById('submittedByNameTarget').value = document.getElementById('submittedByNameSource').value;
        document.getElementById('protocol-form').submit();*/
    }


});