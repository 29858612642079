application.register("layout_header", class extends Stimulus.Controller {
    connect() {
        let header = this.element,
            currentScrollY = 0,
            latestKnownScrollY = 0,
            ticking = false,
            offset = 5;

        function setHeaderState() {
            if (currentScrollY >= offset) {
                header.classList.add("header--not-top");
                if (currentScrollY > latestKnownScrollY){
                    header.classList.remove("header--pinned");
                    header.classList.add("header--unpinned");
                } else {
                    header.classList.remove("header--unpinned");
                    header.classList.add("header--pinned");
                }
            } else {
                header.classList.remove("header--not-top", "header--pinned", "header--unpinned");
            }
            latestKnownScrollY = currentScrollY;
        }

        function update() {
            setHeaderState();
            ticking = false;
        }

        function onScroll() {
            if(!ticking) {
                requestAnimationFrame(update);
                ticking = true;
            }
        }

        bodyLoaded(()=>{
            window.addEventListener('scroll', ()=>{
                currentScrollY = window.scrollY;
                onScroll();
            }, false);
        })
    }
});